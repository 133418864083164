/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Usb({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M14.347 6.4h-3.68v3.68h1.6v2.56c0 0.587 0 0.96-0.587 1.547l-3.68 3.627v-14.88l0.747 1.227 0.907-0.533-2.187-3.627-2.187 3.627 0.907 0.533 0.747-1.227v19.253l-3.36-3.36c-0.587-0.587-0.907-1.067-0.907-1.44v-2.507c0.907-0.213 1.6-1.067 1.6-2.080 0-1.173-0.96-2.133-2.133-2.133s-2.133 0.96-2.133 2.133c0 1.013 0.693 1.813 1.6 2.080v2.507c0 0.907 0.64 1.653 1.227 2.187l4.16 4.16v2.987c-1.227 0.267-2.133 1.333-2.133 2.613 0 1.493 1.173 2.667 2.667 2.667s2.667-1.173 2.667-2.667c0-1.28-0.907-2.347-2.133-2.613v-7.36l4.427-4.427c0.907-0.907 0.907-1.653 0.907-2.293v-2.56h1.013v-3.68zM1.067 12.8c0-0.587 0.48-1.067 1.067-1.067s1.067 0.48 1.067 1.067c0 0.587-0.48 1.067-1.067 1.067s-1.067-0.48-1.067-1.067zM9.067 29.333c0 0.907-0.693 1.6-1.6 1.6s-1.6-0.693-1.6-1.6c0-0.907 0.693-1.6 1.6-1.6s1.6 0.693 1.6 1.6zM11.733 7.467h1.547v1.547h-1.547v-1.547z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Usb;
