/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Graph2({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M3.205 3.205v25.59h25.59v-25.59h-25.59zM27.729 4.271v4.594l-7.023 10.131-4.815-3.805-5.356 7.499-3.162-2.108-3.101 3.615v-19.927h23.457zM4.271 27.729v-1.893l3.296-3.842 3.235 2.157 5.306-7.428 4.827 3.814 6.793-9.8v16.992h-23.457z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Graph2;
