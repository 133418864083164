import React, { useState, useRef, useEffect } from 'react';
import {
  Container, FormLabel, Button, Modal, Form,
} from 'react-bootstrap';
import {
  SubscriptionSettingsRequirement,
  DataCollectionAccountWithSettings,
  OrganizationSubscription,
  OrganizationSubscriptionSetting,
} from '../../controllers/subscription-service/types';
import DisplaySettingInput from '../displaySettingInput/displaySettingInput';
import { displayStringAsLabel, parseValue } from '../../utils/stringFormatting';
import { RegexRowError, isInvalidRegexValue } from '../../utils/validateRegex';
import { dcaInstructions } from '../../utils/dataCollectionConstants';

export type DataCollectionAccountModalProp = {
  settingRequirements: SubscriptionSettingsRequirement[],
  dataCollectionAccount: DataCollectionAccountWithSettings,
  subscriptions: OrganizationSubscription[],
  onSave: (activeDCA: DataCollectionAccountWithSettings) => void,
  onClose: () => void,
};

export default function DataCollectionAccountModal(
  {
    settingRequirements,
    dataCollectionAccount,
    subscriptions,
    onSave,
    onClose,
  }: DataCollectionAccountModalProp,
) {
  const modalBodyRef = useRef<HTMLDivElement>(null);
  const [activeSubscription, setActiveSubscription] = (
    useState<OrganizationSubscription | null>(null)
  );
  const [validationErrors, setValidationErrors] = useState<RegexRowError[]>([]);
  // initial state is a COPY of the original.
  const [activeDCA, setActiveDCA] = useState<DataCollectionAccountWithSettings>(
    { ...dataCollectionAccount },
  );

  // helpers
  const getSettingByKey = (requirement_key: string) => activeDCA.settings
    .find(({ key }) => key === requirement_key)?.value;

  const recordChangedName = (
    _field: string,
    selectedValues: string | string[],
  ) => {
    const newDCA = {
      ...activeDCA,
      name: String(selectedValues),
    };
    setActiveDCA(newDCA);
  };

  const recordChangedSetting = (
    field: string,
    selectedValues: string | string[],
  ) => {
    const newDCA = {
      ...activeDCA,
      name: (field === 'account_name') ? String(selectedValues) : activeDCA.name,
      settings: activeDCA.settings.map(
        (setting) => ((setting.key === field) ? {
          ...setting,
          value: selectedValues,
        } : setting),
      ),
    };
    // add a new record if needed.
    if (activeDCA.settings.filter(({ key }) => key === field).length === 0) {
      newDCA.settings.push({
        id: activeDCA.id,
        organization_id: Number(subscriptions[0].organization_id),
        data_collection_account_id: activeDCA.id,
        key: field,
        value: selectedValues,
        population_status: 'filled',
        expires_at: '',
      });
    }
    setActiveDCA(newDCA);
  };

  // perform regex validations, and if no errors, save.
  const validateThenSave = () => {
    // need to put in default values, as needed.
    const dcaToBeSaved = JSON.parse(JSON.stringify(activeDCA));

    // list of requirements with non-empty defaults
    settingRequirements.filter(
      ({ setting_key, offered_subscription_id, default_value }) => (
        offered_subscription_id === dcaToBeSaved.offered_subscription_id
        && default_value > ''
        && setting_key !== 'aws_secret_name'
      ),
    ).forEach(({ setting_key, default_value }) => {
      // use the default value, if there isn't one
      let unpackagedValue = default_value;
      try {
        unpackagedValue = JSON.parse(default_value);
      } catch (e) {
        (() => true)();
      }
      if (!getSettingByKey(setting_key)) {
        dcaToBeSaved.settings.push({
          id: '',
          organization_id: dcaToBeSaved.organization_id,
          data_collection_account_id: '',
          key: setting_key,
          value: unpackagedValue,
          population_status: 'filled',
          expires_at: '',
        });
      }
    });

    const errors: RegexRowError[] = activeDCA.settings.reduce((
      filtered: RegexRowError[],
      row: OrganizationSubscriptionSetting,
    ) => {
      const requirement = settingRequirements.find(
        ({ offered_subscription_id, setting_key }) => (
          offered_subscription_id === dcaToBeSaved.offered_subscription_id
          && setting_key === row.key
        ),
      );
      if (requirement && isInvalidRegexValue(row.value, requirement.validation_regex)) {
        filtered.push({
          key: row.key,
          message: `'${row.value}' is not an accepted value of ${requirement.input_label}`,
        });
      }
      return filtered;
    }, []);

    setValidationErrors(errors);

    if (errors.length > 0) {
      return;
    }

    onSave(dcaToBeSaved);
  };

  useEffect(() => {
    if (modalBodyRef.current) {
      const { scrollHeight } = modalBodyRef.current as HTMLDivElement;
      modalBodyRef.current.scrollTo({ top: scrollHeight, behavior: 'smooth' });
    }
  }, [activeDCA]);

  useEffect(() => {
    let newActiveSubscription: OrganizationSubscription | null | undefined = null;
    if (activeDCA.offered_subscription_id && subscriptions !== undefined) {
      newActiveSubscription = subscriptions.find(
        ({ offered_subscription_id }) => offered_subscription_id
        === activeDCA.offered_subscription_id,
      );
    }
    if (newActiveSubscription) {
      setActiveSubscription(newActiveSubscription);
    } else {
      setActiveSubscription(null);
    }
  }, [activeDCA, subscriptions]);

  return (
    <Modal
      show
      onHide={onClose}
      size="lg"
      dialogClassName="DataCollectionAccountFrameContainer"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {
            activeDCA.id
              ? `Edit Data Collection Account: ${activeDCA.name}`
              : 'Add Data Collection Account'
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="vh-75 overflow-x-scroll"
        ref={modalBodyRef}
      >
        <Container
          key={`data-collection-account-${activeDCA.id}`}
          className="h-100 col-12 my-2 p-2 mx-auto bg-quicksights-grey"
        >
          <div
            key="account_type"
            className="border-bottom border-secondary mb-3 pb-2"
          >
            <FormLabel>
              <b>
                Account Type
              </b>
              <br />
              {
                activeDCA.id
                  ? null : (
                    <span className="text-a8-grey">
                      Select the retailer and collection type this applies to.
                    </span>
                  )
              }
            </FormLabel>
            {
              activeDCA.id
                ? (
                  <div>
                    {activeSubscription?.retailer_name ?? 'no retailer name'}
                    &nbsp;-&nbsp;
                    {displayStringAsLabel(activeSubscription?.collection_type ?? 'no collection type')}
                  </div>
                ) : (
                  <Form.Select
                    data-testid="DCAModal-subscription-select"
                    aria-label="Select the Account Type that these settings will apply to"
                    onChange={(e) => setActiveDCA({
                      ...activeDCA,
                      offered_subscription_id: e.target.value,
                    })}
                  >
                    <option
                      data-testid="DCAModal-subscription-option-select-one"
                    >
                      -- Select One --
                    </option>
                    {subscriptions.map((subscription) => (
                      <option
                        key={`subscription-${subscription.id}`}
                        value={subscription.offered_subscription_id}
                        data-testid={`DCAModal-subscription-option-${subscription.offered_subscription_id}`}
                      >
                        {subscription.retailer_name}
                        &nbsp;-&nbsp;
                        {displayStringAsLabel(subscription.collection_type)}
                      </option>
                    ))}
                  </Form.Select>
                )
            }
          </div>
          {
            // Display offered_subscription_id specific instructions, if any
            (activeDCA.offered_subscription_id
              && activeDCA.offered_subscription_id in dcaInstructions)
              ? dcaInstructions[activeDCA.offered_subscription_id]
              : null
          }
          { // request an "account name", if we're not already asking for one
            (settingRequirements.filter(
              ({ input_type, offered_subscription_id, setting_key }) => (
                input_type !== 'hidden'
                && offered_subscription_id === activeDCA.offered_subscription_id
                && setting_key === 'account_name'
              ),
            ).length > 0)
              ? null
              : (
                <div
                  key="dca-field-account_name"
                  data-testid="dca-field-account_name"
                  className="mb-4"
                >
                  <FormLabel
                    className="d-block mb-1"
                  >
                    <b>
                      Account Name
                    </b>
                    <br />
                    <span className="text-a8-grey">
                      Name of the Account
                    </span>
                    {
                      validationErrors.filter(
                        ({ key }) => key === 'account_name',
                      ).map(
                        ({ message }) => (
                          <div
                            className="text-danger"
                            key="valdation-error-account_name"
                          >
                            {message}
                          </div>
                        ),
                      )
                    }
                  </FormLabel>
                  <DisplaySettingInput
                    inputType="string"
                    dataCollectionAccountId={activeDCA.id}
                    field="account_name"
                    value={activeDCA.name}
                    recordChange={recordChangedName}
                  />
                </div>
              )
          }
          {
            settingRequirements.filter(
              ({ input_type, offered_subscription_id }) => (
                input_type !== 'hidden'
                && offered_subscription_id === activeDCA.offered_subscription_id
              ),
            ).map(({
              input_label, setting_description, setting_key, default_value, input_type,
            }) => (
              <div
                key={`dca-field-${setting_key}`}
                className="mb-4"
              >
                <FormLabel
                  className="d-block mb-1"
                >
                  <b>
                    {input_label}
                  </b>
                  <br />
                  <span className="text-a8-grey">
                    {setting_description}
                  </span>
                  {
                    validationErrors.filter(
                      ({ key }) => key === setting_key,
                    ).map(
                      ({ message }) => (
                        <div
                          className="text-danger"
                          key={`valdation-error-${setting_key}`}
                        >
                          {message}
                        </div>
                      ),
                    )
                  }
                </FormLabel>
                <DisplaySettingInput
                  inputType={input_type}
                  dataCollectionAccountId={activeDCA.id}
                  field={setting_key}
                  value={getSettingByKey(setting_key) ?? parseValue(default_value)}
                  recordChange={recordChangedSetting}
                />
              </div>
            ))
          }
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          className="pull-right ms-2"
          onClick={onClose}
          data-testid="DCAModal-close"
        >
          Close
        </Button>
        <Button
          variant="primary"
          className="pull-right ms-2"
          onClick={validateThenSave}
          data-testid="DCAModal-save"
        >
          {
            activeDCA.id
              ? 'Update'
              : 'Add'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
