import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  createEmbeddingContext,
  ContentOptions,
  DashboardExperience,
  FrameOptions,
  Parameter,
  ParameterValue,
  Sheet,
} from 'amazon-quicksight-embedding-sdk';
import { useLocation } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import { fetchViews } from '../../controllers/user-service';
import { AsyncState } from '../../utils/webRequests.type';
import { selectCurrentOrgId } from '../../reducers/profile/profileSlice';
import NotFound from '../NotFound/NotFound';
import './QuickSightsDashboard.css';
import LoadingPage from '../LoadingPage/LoadingPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import NotesPanel from '../../components/notesPanel/notesPanel';
import GlobalConfig from '../../app/config';
import sendRumError from '../../utils/datadogRum';

type QuickSightsDashBoardProps = {
  dashboardPath: string,
  parameters?: { [x: string]: string },
};

const frameOptionsTemplate: FrameOptions = {
  url: 'undefined',
  container: 'undefined',
  height: '700px',
  width: '100%',
  withIframePlaceholder: false, // disabling - was a visual oddity: GAS-4609
  resizeHeightOnSizeChangedEvent: false,
};

const defaultParameters: Parameter[] = [
  {
    Name: 'itemNotesId',
    Values: [0], // must be string or number.  can't be false
  },
];

const contentOptionsTemplate: ContentOptions = {
  locale: 'en-US',
  parameters: defaultParameters,
  toolbarOptions: {
    undoRedo: true,
    reset: true,
    export: true,
  },
  attributionOptions: {
    overlayContent: false,
  },
  sheetOptions: {
    // allows the Dashboards to display multiple "tabs"
    singleSheet: false,
  },
};

const parametersFromQS = (qsParameters: { [x: string]: string }) => Object.entries(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  qsParameters as any,
).map(
  ([key, value]) => ({
    Name: String(key),
    Values: [value as ParameterValue],
  }),
);

export default function QuickSightsDashboard(props: QuickSightsDashBoardProps) {
  const dashboardContainerRef = useRef(null);
  const [fetchStatus, setFetchStatus] = useState<AsyncState>('uninitialized');
  const [dashboardLoadStatus, setDashboardLoadStatus] = useState<AsyncState>('uninitialized');
  const currentOrgID = useSelector(selectCurrentOrgId);
  const { dashboardPath, parameters: qsParameters = {} } = props;
  const [notesProductId, setNotesProductId] = useState<string>('');
  const [showNotes, setShowNotes] = useState<boolean>(false);
  const location = useLocation();
  const isComplete = (fetchStatus) === 'completed';

  const dashboardFrame = useRef<DashboardExperience | null>(null);

  const getActiveSheet = async () => {
    if (dashboardFrame.current) {
      const selectedSheetId = await dashboardFrame.current.getSelectedSheetId();
      const sheetList: Sheet[] = await dashboardFrame.current.getSheets();
      const filteredSheets = sheetList.filter(
        (s) => s.SheetId === selectedSheetId,
      );
      if (filteredSheets.length > 0) {
        return filteredSheets[0];
      }
    }
    return 'None';
  };

  useEffect(() => {
    setShowNotes(false);
    setNotesProductId('');
  }, [location]);

  useEffect(() => {
    if (dashboardLoadStatus === 'completed' && dashboardFrame.current != null) {
      setFetchStatus('loading');
      dashboardFrame.current.setParameters(parametersFromQS(qsParameters)).then(
        () => setFetchStatus('completed'),
      );
    }
  }, [qsParameters]);

  useEffect(() => {
    setFetchStatus('loading');
    fetchViews(currentOrgID, dashboardPath)
      .then((response) => {
        setFetchStatus('completed');
        setDashboardLoadStatus('loading');

        const embedDashboard = async () => {
          const embeddingContext = await createEmbeddingContext({});

          const frameOptions = {
            ...frameOptionsTemplate,
            url: response.data[0].embed_url.toString(),
            container: dashboardContainerRef.current as unknown as HTMLDivElement,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange: (changeEvent: any, metadata: any) => {
              switch (changeEvent.eventName) {
                case 'INVALID_URL': {
                  const invalidURLError = new Error(
                    'Invalid url sent to frame',
                    {
                      cause: {
                        changeEvent,
                        metadata,
                      },
                    },
                  );
                  setDashboardLoadStatus('failed');
                  sendRumError(invalidURLError);
                  break;
                }
                default: {
                  break;
                }
              }
            },
          };

          const contentOptions: ContentOptions = {
            ...contentOptionsTemplate,
            parameters: parametersFromQS(qsParameters).concat(defaultParameters),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onMessage: async (messageEvent: any) => {
              switch (messageEvent.eventName) {
                case 'CONTENT_LOADED': {
                  datadogRum.addAction(
                    'Quicksight Content Loaded',
                    {
                      dashboardPath,
                      documentTitle: messageEvent.message.title,
                      selectedSheet: await getActiveSheet(),
                    },
                  );
                  setDashboardLoadStatus('completed');
                  break;
                }
                case 'ERROR_OCCURRED': {
                  if (['production', 'development'].includes(GlobalConfig.config.ENV)) {
                    sendRumError(new Error('Error received from QuickSight', {
                      cause: messageEvent.message,
                    }));
                  }
                  setDashboardLoadStatus('failed');
                  break;
                }
                case 'PARAMETERS_CHANGED': {
                  messageEvent.message.changedParameters.forEach(
                    (parameter: Parameter) => {
                      // hook to help with visibility of itemNotes
                      // if more than 1 parameter changed at once,
                      // then the user didn't intend to trigger item notes
                      if (parameter.Name === 'itemNotesId'
                        && messageEvent.message.changedParameters.length === 1
                      ) {
                        const productId = parameter.Values.length > 0
                          ? String(parameter.Values[0])
                          : '';
                        if (productId) {
                          setNotesProductId(productId);
                          setShowNotes(true);
                        }
                      }
                    },
                  );
                  break;
                }
                case 'SELECTED_SHEET_CHANGED': {
                  datadogRum.addAction(
                    'Quicksight Selected Sheet Changed',
                    {
                      dashboardPath,
                      selectedSheet: messageEvent.message.selectedSheet,
                    },
                  );
                  break;
                }
                default: {
                  break;
                }
              }
            },
          };

          dashboardFrame.current = await embeddingContext.embedDashboard(
            frameOptions,
            contentOptions,
          );
        };
        if (dashboardFrame.current !== null) {
          dashboardFrame.current.reset()
            .then(
              () => embedDashboard(),
            );
        } else {
          embedDashboard();
        }
      })
      .catch((error) => {
        sendRumError(error);
        setFetchStatus('failed');
      });
  }, [currentOrgID, dashboardPath]);

  if (fetchStatus === 'failed') {
    return (<NotFound />);
  }

  if (dashboardLoadStatus === 'failed') {
    return (<ErrorPage />);
  }

  return (
    <div className="quicksight-dashboard-page">
      <LoadingPage
        className={`page bg-quicksight-grey ${!isComplete ? 'visible' : 'invisible d-none'}`}
      />
      <div
        ref={dashboardContainerRef}
        id="embeddingContainer"
        data-testid="embeddingContainer"
        className={`page bg-quicksight-grey ${isComplete ? 'visible' : 'invisible'}`}
      >
        <NotesPanel
          productId={notesProductId}
          show={showNotes}
          setShowNotesCallback={setShowNotes}
          afterClose={() => setNotesProductId('')}
        />
      </div>
    </div>
  );
}

QuickSightsDashboard.defaultProps = {
  parameters: {},
};
