/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Id({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M16.785 12.328h7.342v1.049h-7.342v-1.049z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M16.784 18.623h7.343v1.049h-7.343v-1.049z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M16.784 15.475h5.237v1.049h-5.237v-1.049z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M15.735 6.034v-3.147h-4.196v3.147h-11.539v23.079h27.275v-23.079h-11.539zM26.226 7.083v2.098h-10.49v-2.098h10.49zM12.588 3.936h2.098v5.245h-2.098v-5.245zM1.049 7.083h10.49v2.098h-10.49v-2.098zM1.049 28.064v-17.833h25.177v17.833h-25.177z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M15.463 23.402c-0.757-0.315-2.545-0.936-3.652-1.263-0.094-0.030-0.108-0.035-0.108-0.428 0-0.324 0.133-0.65 0.263-0.927 0.14-0.301 0.308-0.806 0.368-1.259 0.168-0.196 0.396-0.58 0.545-1.313 0.128-0.645 0.069-0.881-0.017-1.102-0.009-0.023-0.018-0.046-0.026-0.069-0.033-0.152 0.012-0.938 0.123-1.549 0.076-0.418-0.019-1.309-0.596-2.046-0.364-0.466-1.060-1.037-2.334-1.117l-0.699 0.001c-1.251 0.079-1.949 0.651-2.312 1.116-0.577 0.737-0.673 1.628-0.597 2.046 0.112 0.611 0.156 1.397 0.124 1.546-0.006 0.026-0.016 0.049-0.026 0.072-0.085 0.221-0.146 0.456-0.016 1.102 0.146 0.733 0.375 1.117 0.544 1.313 0.058 0.453 0.226 0.958 0.368 1.259 0.103 0.22 0.153 0.519 0.153 0.942 0 0.393-0.015 0.398-0.103 0.426-1.144 0.338-2.967 0.996-3.646 1.293-0.539 0.231-0.67 0.646-0.67 1.020 0 0.105 0 0.268 0 0.453h1.049v-0.453c0-0.017 0.001-0.030 0.002-0.040 0.009-0.005 0.021-0.011 0.039-0.018 0.645-0.283 2.433-0.927 3.543-1.255 0.835-0.265 0.835-1.049 0.835-1.426 0-0.581-0.080-1.022-0.252-1.389-0.105-0.224-0.235-0.621-0.277-0.946l-0.041-0.313-0.206-0.239c-0.032-0.036-0.192-0.244-0.309-0.833-0.080-0.397-0.051-0.471-0.035-0.514 0.028-0.068 0.051-0.135 0.073-0.23 0.092-0.424-0.030-1.483-0.116-1.955-0.029-0.155 0.012-0.73 0.39-1.214 0.332-0.425 0.844-0.665 1.522-0.713l0.629-0.001c0.691 0.049 1.209 0.289 1.541 0.714 0.379 0.484 0.419 1.059 0.391 1.213-0.086 0.472-0.208 1.53-0.117 1.957l0.009 0.041 0.011 0.040c0.014 0.046 0.030 0.090 0.052 0.149 0.017 0.044 0.046 0.117-0.033 0.513-0.119 0.59-0.28 0.8-0.312 0.836l-0.204 0.237-0.041 0.31c-0.043 0.326-0.172 0.725-0.277 0.949-0.17 0.36-0.364 0.841-0.364 1.375 0 0.377 0 1.162 0.861 1.434 1.048 0.309 2.805 0.916 3.535 1.221 0.111 0.047 0.151 0.105 0.162 0.127v0.424h1.049c0-0.184 0-0.348 0-0.453-0-0.374-0.259-0.831-0.797-1.063z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Id;
