/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Wine({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M15.84 1.653v0l-5.6 5.6c-3.413 3.413-3.52 8.853-0.373 12.427l-4.533 4.533-4.533-4.533-0.8 0.853 9.813 9.813 0.747-0.747-4.533-4.533 4.533-4.533c3.573 3.147 9.013 3.040 12.427-0.373l2.987-2.987h0.107v-0.107l2.56-2.56-12.8-12.853zM22.24 19.413c-3.093 3.093-8.213 3.093-11.307 0-0.693-0.693-1.173-1.44-1.6-2.24h15.147l-2.24 2.24zM25.547 16.053h-16.587c-0.853-2.72-0.16-5.813 1.973-8l4.907-4.853 11.307 11.307-1.6 1.547z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Wine;
