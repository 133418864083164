import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import PageHeader from '../../components/PageHeader/PageHeader';
import SnackBar from '../../components/snackBar/SnackBar';
import PermissionsSwitchBoard from '../../components/PermissionsSwitchBoard/PermissionsSwitchBoard';
import { selectCurrentOrgId } from '../../reducers/profile/profileSlice';
import { createUser } from '../../controllers/user-service';
import { AsyncState, MessageMap } from '../../utils/webRequests.type';
import validateEmail from '../../utils/validateEmail';
import './UsersNew.css';
import { GrantMapMutable, UserGrantMutable } from '../../controllers/auth/types';
import { NO_PERM_GRANT_MAP } from '../../utils/constants';
import { EditableUserFields, RoleSelection } from '../../controllers/user-service/types';
import sendRumError from '../../utils/datadogRum';

export default function UsersNew() {
  const [role, setRole] = useState<RoleSelection>('Team Member');
  const [email, setEmail] = useState<string>('');
  const [permissions, setPermissions] = useState<GrantMapMutable>(NO_PERM_GRANT_MAP);
  const [messageMap, setMessage] = useState<MessageMap>({ show: false, message: '', alertType: 'danger' });
  const currentOrgID = useSelector(selectCurrentOrgId);
  const [saveNewUserState, setSaveNewUserState] = useState<AsyncState>('uninitialized');

  const handleOnChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailAddress = e.currentTarget.value.trim();
    setEmail(emailAddress);
  };

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();

    // exit early if the button was double clicked or something
    if (saveNewUserState === 'loading') {
      return;
    }

    setSaveNewUserState('loading');
    const grants: UserGrantMutable[] = [];
    Object.keys(permissions).forEach((key: string) => {
      if (permissions[key as UserGrantMutable] === true) {
        grants.push(key as UserGrantMutable);
      }
    });
    const payload: EditableUserFields = {
      email,
      default_organization_id: currentOrgID,
      grants: { [`${currentOrgID}`]: grants },
      first_name: 'New',
      last_name: 'User',
    };
    createUser(currentOrgID, payload)
      .then(() => {
        setEmail('');
        setRole('Team Member');
        setPermissions(NO_PERM_GRANT_MAP);
        const message = 'The invitation was sent to the user successfully.';
        setMessage({ show: true, message, alertType: 'success' });
        setSaveNewUserState('completed');
      })
      .catch((error) => {
        sendRumError(error);
        let message = 'Error inviting user';

        try {
          message = `Error inviting user: ${error?.response.data.detail.join(', ')}`;
        } catch { } // eslint-disable-line no-empty

        setMessage({ show: true, message, alertType: 'danger' });
        setSaveNewUserState('failed');
      });
  };

  const handleRoleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    assignedRole: RoleSelection,
  ) => {
    if (role !== assignedRole) {
      setRole(assignedRole);
    }
  };

  return (
    <div id="UsersNewPage" className="page h-100">
      <div id="error-container" className="col-8 offset-md-4">
        <SnackBar
          style={{
            position: 'absolute', width: '45vw', zIndex: 1000,
          }}
          alertType={messageMap.alertType}
          header={messageMap.alertType === 'danger' ? 'Error' : 'Success'}
          alertText={messageMap.message}
          show={messageMap.show}
          onClose={() => setMessage({ show: false, message: '', alertType: 'danger' })}
        />
      </div>
      <Form
        className="h-100 col-12"
        onSubmit={(event) => handleSubmit(event)}
      >
        <PageHeader title="Add New User" returnText="Back to Manage Users" returnLink="/users">
          <Button
            type="submit"
            className="create-user-button"
            data-testid="create-user-button"
            disabled={!validateEmail(email) || (saveNewUserState === 'loading')}
          >
            Send Invitation
          </Button>
        </PageHeader>
        <div className="PageContent row">
          <div className="col-3 h-100 info-pane">
            <Form.Label htmlFor="new-user-email">
              <strong>Enter User Email</strong>
            </Form.Label>
            <Form.Control
              id="new-user-email"
              type="email"
              value={email}
              placeholder="Enter new user's email"
              onChange={handleOnChangeEmail}
            />
            <br />
            <Form.Label htmlFor="admin-role-radio">
              <strong>Role &amp; Permissions</strong>
            </Form.Label>
            <Form.Group>
              <Form.Check
                inline
                label="Admin"
                name="role"
                type="radio"
                id="admin-role-radio"
                checked={role === 'Admin'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRoleChange(e, 'Admin')}
              />
              <p className="text-a8-grey role-description">
                Can add new users, edit permissions, remove users,
                and has access to all dashboards
              </p>
              <Form.Check
                inline
                label="Viewer"
                name="role"
                type="radio"
                id="viewer-role-radio"
                checked={role === 'Viewer'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRoleChange(e, 'Viewer')}
                disabled={role === 'Owner'}
              />
              <p className="text-a8-grey role-description">
                Is able to view all dashboards and data but is not able to make edits
              </p>
              <Form.Check
                inline
                label="Team Member"
                name="role"
                type="radio"
                id="team-member-role-radio"
                checked={role === 'Team Member'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRoleChange(e, 'Team Member')}
              />
              <p className="text-a8-grey role-description">
                Has custom access granted by an organization admin
              </p>
            </Form.Group>
          </div>
          <div className="permisions-page-container col-9 h-100 container pull-right">
            <PermissionsSwitchBoard
              setPermissions={setPermissions}
              initialPermissions={permissions}
              roleHook={role}
            />
          </div>
        </div>
      </Form>
    </div>
  );
}
