import React from 'react';
import { Form } from 'react-bootstrap';

function DisplaySettingInput(
  {
    inputType,
    dataCollectionAccountId,
    field,
    value,
    recordChange = () => {},
  } : {
    inputType: string,
    dataCollectionAccountId: string,
    field: string,
    value: string | string[],
    recordChange?: (
      field: string,
      selectedValues: string | string[],
    ) => void,
  },
) : JSX.Element | null {
  const onChangeFunc = recordChange ?? (() => null);

  const shortInputType = (inputType ?? '').split('[')[0];
  let optionList: string[] = [];

  const saveMultiSwitch = () => {
    const selector = `switch:${dataCollectionAccountId}:${field}:`;
    const siblingSwitches = document.querySelectorAll(`[id^="${selector}"]`);

    const selectedValues: string[] = Array.from(siblingSwitches).filter(
      (node: Element) => (node as HTMLInputElement).checked,
    ).map(
      (node: Element) => node.getAttribute('value') ?? '',
    );
    onChangeFunc(field, selectedValues);
  };

  const conditionedValueArray = Array.isArray(value) ? value : [];

  switch (shortInputType) {
    case 'hidden': return null;
    case 'string': return (
      <Form.Control
        id={`input:${dataCollectionAccountId}:${field}`}
        data-testid={`input:${dataCollectionAccountId}:${field}`}
        name={`${dataCollectionAccountId}:${field}`}
        type="input"
        autoComplete="off"
        value={value || ''}
        onChange={(e) => onChangeFunc(field, e.currentTarget.value)}
      />
    );
    case 'read_only': return (
      <Form.Control
        type="text"
        data-testid={`readOnly:${dataCollectionAccountId}:${field}`}
        placeholder={Array.isArray(value) ? JSON.stringify(value) : value}
        readOnly
        disabled
      />
    );
    case 'password': return (
      <Form.Control
        id={`input:${dataCollectionAccountId}:${field}`}
        data-testid={`input:${dataCollectionAccountId}:${field}`}
        name={`${dataCollectionAccountId}:${field}`}
        type="password"
        autoComplete="off"
        value={value || ''}
        onChange={(e) => onChangeFunc(field, e.currentTarget.value)}
      />
    );
    case 'date': return (
      <Form.Control
        id={`input:${dataCollectionAccountId}:${field}`}
        data-testid={`input:${dataCollectionAccountId}:${field}`}
        name={`${dataCollectionAccountId}:${field}`}
        type="date"
        autoComplete="off"
        value={value || ''}
        onChange={(e) => onChangeFunc(field, e.currentTarget.value)}
      />
    );
    case 'enum':
      optionList = JSON.parse(`[${inputType?.split('[')[1]}`);
      optionList.sort();
      return (
        <Form.Select
          id={`select:${dataCollectionAccountId}:${field}`}
          data-testid={`select:${dataCollectionAccountId}:${field}`}
          name={`${dataCollectionAccountId}:${field}`}
          value={value || ''}
          onChange={(e) => onChangeFunc(field, e.currentTarget.value)}
        >
          {optionList.map(
            (option) => (
              <option
                key={`option:${dataCollectionAccountId}:${field}:${option}`}
                data-testid={`option:${dataCollectionAccountId}:${field}:${option}`}
              >
                {option}
              </option>
            ),
          )}
        </Form.Select>
      );
    case 'multiselect':
      optionList = JSON.parse(`[${inputType?.split('[')[1]}`);
      optionList.sort();
      return (
        <>
          {
            optionList.map(
              (option) => (
                <div
                  className="row"
                  key={`switch:${dataCollectionAccountId}:${field}:${option}`}
                >
                  <div className="col-2">
                    <Form.Check
                      type="switch"
                      id={`switch:${dataCollectionAccountId}:${field}:${option}`}
                      data-testid={`switch:${dataCollectionAccountId}:${field}:${option}`}
                      value={option}
                      onChange={() => saveMultiSwitch()}
                      checked={conditionedValueArray.includes(option)}
                    />
                  </div>
                  <div className="col-10">
                    {option}
                  </div>
                </div>
              ),
            )
          }
        </>
      );
    default: return null;
  }
}

DisplaySettingInput.defaultProps = {
  recordChange: () => {},
};

export default DisplaySettingInput;
