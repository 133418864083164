/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Battery({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M0.020 10.649v10.702c0 0.873 0.708 1.58 1.58 1.58h13.348v-13.861h-13.348c-0.872 0-1.58 0.707-1.58 1.58zM13.882 21.864h-12.282c-0.283 0-0.513-0.231-0.513-0.514v-10.702c0-0.283 0.23-0.513 0.513-0.513h12.282v11.729z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M31.98 16c0-1.757-1.417-3.183-3.171-3.197v-2.154c0-0.873-0.707-1.58-1.58-1.58h-11.215v1.066h11.215c0.283 0 0.513 0.23 0.513 0.513v10.702c0 0.283-0.23 0.514-0.513 0.514h-11.215v1.066h11.215c0.873 0 1.58-0.707 1.58-1.58v-2.153c1.753-0.015 3.171-1.439 3.171-3.197zM28.809 18.13v-4.26c1.163 0.016 2.104 0.963 2.104 2.13s-0.942 2.114-2.104 2.13z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Battery;
