/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import './UsersEdit.css';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Form, Button, Spinner, Alert,
} from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { AsyncState, MessageMap } from '../../utils/webRequests.type';
import PageHeader from '../../components/PageHeader/PageHeader';
import PermissionsSwitchBoard from '../../components/PermissionsSwitchBoard/PermissionsSwitchBoard';
import {
  deleteUser,
  fetchUser,
  updateUser,
} from '../../controllers/user-service';
import { selectCurrentOrgId } from '../../reducers/profile/profileSlice';
import LoadingPage from '../LoadingPage/LoadingPage';
import SnackBar from '../../components/snackBar/SnackBar';
import DeleteModal from '../../components/modal/deleteModal/deleteModal';
import {
  hasGrants,
} from '../../controllers/auth';
import {
  UserGrantMutable,
  GrantMap,
  GrantMapMutable,
} from '../../controllers/auth/types';
import { NO_PERM_GRANT_MAP } from '../../utils/constants';
import {
  RoleSelection,
  UpdateUserPayload,
  UserType,
} from '../../controllers/user-service/types';
import sendRumError from '../../utils/datadogRum';

const emptyUser: UserType = {
  authentication_id: '',
  email: '',
  first_name: '',
  id: '',
  last_name: '',
  grants: {},
};

export default function UsersEdit() {
  const { userId } = useParams();

  const [user, setUser] = useState<UserType>(emptyUser);
  const [saveState, setSaveState] = useState<AsyncState>('uninitialized');
  const [deleteState, setDeleteState] = useState<AsyncState>('uninitialized');
  const [loadUserState, setLoadUserState] = useState<AsyncState>('uninitialized');
  const [hasOwnerRights, setHasOwnerRights] = useState<boolean>(false);
  const navigate = useNavigate();

  const [initialPermissions, setInitialPermissions] = useState<Partial<GrantMap>>();
  const [permissions, setPermissions] = useState<GrantMapMutable>(NO_PERM_GRANT_MAP);
  const [permissionsChanged, setPermissionsChanged] = useState<boolean>(false);

  const [initialRole, setInitialRole] = useState<RoleSelection>('Team Member');
  const [role, setRole] = useState<RoleSelection>('Team Member');
  const [roleChanged, setRoleChanged] = useState<boolean>(false);

  const [messageMap, setMessage] = useState<MessageMap>({ show: false, message: '', alertType: 'danger' });
  const [deleteUserMessage, setDeleteUserMessage] = useState<JSX.Element | string>('');
  const [deleteElement, setDeleteElement] = useState<JSX.Element | string>('Delete');

  const currentOrgID = useSelector(selectCurrentOrgId);

  const loadPermissions = (orgGrants: string[]) => {
    const newPermissions = { ...permissions };

    Object.keys(permissions).map((grant: string) => {
      newPermissions[grant as unknown as UserGrantMutable] = orgGrants.includes(grant);
      return grant;
    });
    setPermissions(newPermissions);
    setInitialPermissions(newPermissions);
  };

  useEffect(() => {
    setRoleChanged(role !== initialRole);
  }, [role, initialRole]);

  useEffect(() => {
    if (!userId || loadUserState !== 'uninitialized') {
      return;
    }

    setLoadUserState('loading');

    fetchUser(Number(currentOrgID), userId)
      .then((response) => {
        const queriedUser = response.data;
        let roleCalc: RoleSelection = 'Team Member';
        if (queriedUser.grants[String(currentOrgID)].includes('ORG_ADMIN')) {
          roleCalc = 'Admin';
        } else if (queriedUser.grants[String(currentOrgID)].includes('ORG_OWNER')) {
          roleCalc = 'Owner';
        } else if (queriedUser.grants[String(currentOrgID)].includes('VIEWER')) {
          roleCalc = 'Viewer';
        }

        if (roleCalc === 'Owner') {
          setHasOwnerRights(true);
        } else {
          setHasOwnerRights(false);
        }
        setRole(roleCalc);
        setInitialRole(roleCalc);

        // saves the data..
        setUser(queriedUser);

        // saves the permissions for display
        loadPermissions(queriedUser.grants[String(currentOrgID)]);
        setLoadUserState('completed');

        setDeleteUserMessage(
          <span>
            Enter &quot;
            <span
              className="text-blushing-salmon"
            >
              {queriedUser.email}
            </span>
            &quot; in the field below to remove
            {' '}
            <b>
              {queriedUser.first_name}
              {' '}
              {queriedUser.last_name}
            </b>
            {' '}
            from your organization.
          </span>,
        );
      })
      .catch((error) => {
        sendRumError(error);
        setLoadUserState('failed');
      });
  }, [loadUserState]);

  useEffect(() => {
    if (typeof initialPermissions === 'undefined') {
      setPermissionsChanged(false);
      return;
    }
    const unChangedPerms = JSON.stringify(permissions) === JSON.stringify(initialPermissions);
    setPermissionsChanged(!unChangedPerms);
  }, [permissions, initialPermissions]);

  // will pass this to deleteModal
  const handleDeleteUser = () => {
    if (deleteState === 'loading') {
      return;
    }

    setDeleteState('loading');

    deleteUser(String(userId), currentOrgID)
      .then(() => {
        const message = 'The user has been deleted successfully.';
        setDeleteState('completed');
        setMessage({ show: true, message, alertType: 'success' });
        navigate('/users');
      })
      .catch((error) => {
        sendRumError(error);
        const message = `Error deleting user: ${error}`;
        setDeleteState('failed');
        setMessage({ show: true, message, alertType: 'danger' });
        setLoadUserState('uninitialized');
      });
  };

  useEffect(() => {
    switch (deleteState) {
      case 'loading':
        setDeleteElement(
          <div className="fit-spinner">
            <Spinner animation="border" role="status" />
          </div>,
        );
        break;
      case 'completed':
        setDeleteElement('Deleted');
        break;
      case 'failed':
        setDeleteElement('Failed');
        break;
      case 'uninitialized':
      default:
        setDeleteElement('Delete');
        break;
    }
  }, [deleteState]);

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();

    if (saveState === 'loading') {
      return;
    }

    setSaveState('loading');

    setInitialPermissions(permissions);
    const grants: UserGrantMutable[] = [];
    Object.keys(permissions).forEach((key: string) => {
      if (permissions[key as UserGrantMutable] === true) {
        grants.push(key as UserGrantMutable);
      }
    });

    const payload: UpdateUserPayload = {
      grants: { [`${currentOrgID}`]: grants },
    };

    updateUser(String(userId), currentOrgID, payload)
      .then(() => {
        const message = 'The user has been updated successfully.';
        setSaveState('completed');
        setMessage({ show: true, message, alertType: 'success' });
        setLoadUserState('uninitialized');
      })
      .catch((error) => {
        sendRumError(error);
        const message = `Error updating user: ${error?.response.data.detail.join(', ')}`;
        setSaveState('failed');
        setMessage({ show: true, message, alertType: 'danger' });
        setLoadUserState('uninitialized');
      });
  };

  const isAllowedToUpdate = hasGrants(currentOrgID, ['USER_ADMIN']);

  let saveNode: string | React.ReactNode;

  switch (saveState) {
    case 'loading':
      saveNode = (
        <div className="fit-spinner">
          <Spinner animation="border" role="status" />
        </div>
      );
      break;
    case 'completed':
      // Has same logic as "disabled" attribute for the save button
      if (!isAllowedToUpdate || hasOwnerRights || (!roleChanged && !permissionsChanged)) {
        saveNode = 'Saved';
      } else {
        saveNode = 'Save';
      }
      break;
    case 'failed':
      saveNode = 'failed';
      break;
    default:
      saveNode = 'Save';
      break;
  }

  switch (loadUserState) {
    case 'uninitialized':
    case 'loading':
      return <LoadingPage />;
    case 'failed':
      return (
        <div className="container h-100">
          <div className="row h-100 justify-content-center align-items-center">
            <Alert variant="danger">
              There was an error loading this user. Please make sure you have selected the correct organization.
            </Alert>
          </div>
        </div>
      );
    case 'completed':
    default:
      return (
        <div id="UsersEditPage" className="page h-100">
          <div id="error-container" className="col-8 offset-md-4">
            <SnackBar
              style={{
                position: 'absolute', width: '45vw', zIndex: 1000,
              }}
              alertType={messageMap.alertType}
              header={messageMap.alertType === 'danger' ? 'Error' : 'Success'}
              alertText={messageMap.message}
              show={messageMap.show}
              onClose={() => setMessage({ show: false, message: '', alertType: 'danger' })}
            />
          </div>
          <Form
            className="h-100 col-12"
            onSubmit={(event) => handleSubmit(event)}
          >
            <PageHeader title="Edit User Permissions" returnText="Back to Manage Users" returnLink="/users">
              <DeleteModal
                buttonClassName="delete-user-button"
                modalClassName="delete-user-modal"
                title="Confirm Deletion"
                onDelete={handleDeleteUser}
                deleteElement={deleteElement}
                challengeText={user.email}
                disabled={!isAllowedToUpdate || hasOwnerRights}
              >
                {deleteUserMessage}
              </DeleteModal>
              <Button
                type="submit"
                className="save-user-button"
                data-testid="save-user-button"
                disabled={!isAllowedToUpdate || hasOwnerRights || (!roleChanged && !permissionsChanged)}
              >
                {saveNode}
              </Button>
            </PageHeader>
            <div className="PageContent row">
              <div className="col-3 h-100 info-pane">
                <strong>Name</strong>
                <p>
                  {user.first_name}
                  {' '}
                  {user.last_name}
                </p>
                <strong>User Email</strong>
                <p>
                  {user.email}
                </p>
                <Form.Label htmlFor="user-email">
                  <strong>Role & Permissions</strong>
                </Form.Label>
                <Form.Group>
                  <Form.Check
                    inline
                    label="Admin"
                    name="role"
                    type="radio"
                    id="admin-role-radio"
                    onClick={() => setRole('Admin')}
                    disabled={hasOwnerRights}
                    defaultChecked={hasOwnerRights || (initialRole === 'Admin')}
                  />
                  <p className="text-a8-grey role-description">
                    Can add new users, edit permissions, remove users, and has access to all dashboards
                  </p>
                  <Form.Check
                    inline
                    label="Viewer"
                    name="role"
                    type="radio"
                    id="viewer-role-radio"
                    onClick={() => setRole('Viewer')}
                    disabled={hasOwnerRights}
                    defaultChecked={initialRole === 'Viewer'}
                  />
                  <p className="text-a8-grey role-description">
                    Is able to view all dashboards and data but is not able to make edits
                  </p>
                  <Form.Check
                    inline
                    label="Team Member"
                    name="role"
                    type="radio"
                    id="team-member-role-radio"
                    onClick={() => setRole('Team Member')}
                    disabled={hasOwnerRights}
                    defaultChecked={initialRole === 'Team Member'}
                  />
                  <p className="text-a8-grey role-description">
                    Has custom access granted by an organization admin
                  </p>
                </Form.Group>
              </div>
              <div className="permisions-page-container col-9 h-100 container">
                <PermissionsSwitchBoard
                  setPermissions={setPermissions}
                  initialPermissions={permissions}
                  roleHook={role}
                  isAdmin={hasOwnerRights || (role === 'Admin')}
                />
              </div>
            </div>
          </Form>
        </div>
      );
  }
}
