import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Modal,
  Spinner,
} from 'react-bootstrap';

import './UploadTermsModal.css';
import { Column } from 'react-table';
import { useSelector } from 'react-redux';
import {
  SearchTermsFileResults,
  ResultsTermUnchangedMessage,
  ResultsTermIncompatibleMessage,
  ResultsTermNewMessage,
  ResultsTermChangedMessage,
  SearchTermRowMessage,
  SearchTermNewData,
  SearchUpdateWithId,
} from '../../../controllers/sov-service/types';
import {
  postSearchTermSet,
  putSearchTermSet,
} from '../../../controllers/sov-service';
import { AsyncState } from '../../../utils/webRequests.type';
import { selectCurrentOrgId } from '../../../reducers/profile/profileSlice';
import sendRumError from '../../../utils/datadogRum';
import ListResultItems from '../ListResultItems/ListResultItems';

// display a single result of the payload_to_send is the same as current
const changedTermGroupDisplay = (d: ResultsTermChangedMessage) => {
  if (
    !Object.prototype.hasOwnProperty.call(d.payload_to_send, 'group')
    || !d.payload_to_send.group
    || d.payload_to_send.group === d.current.group
  ) {
    return (
      <span>
        {d.current.group}
      </span>
    );
  }
  return (
    <span>
      {d.current.group}
      {' '}
      &rarr;
      {' '}
      {d.payload_to_send.group}
    </span>
  );
};

const warningErrorMessageDisplay = (
  details: SearchTermRowMessage[],
) => (
  <div>
    <ul>
      {details.map(
        (detail) => (
          <li key={detail.message}>
            {detail.message}
          </li>
        ),
      )}
    </ul>
  </div>
);

const errorColumns: Column<ResultsTermIncompatibleMessage>[] = [
  {
    Header: 'Row #',
    accessor: 'row_number',
  },
  {
    Header: 'Search Term',
    accessor: (d) => (
      <span>
        {d.payload_received.term}
      </span>
    ),
  },
  {
    Header: 'Warnings',
    accessor: (d) => warningErrorMessageDisplay(d.warnings),
  },
  {
    Header: 'Errors',
    accessor: (d) => warningErrorMessageDisplay(d.errors),
  },
];

const newTermColumns: Column<ResultsTermNewMessage>[] = [
  {
    Header: 'Retailer',
    accessor: (d) => (
      <span>
        {d.new_search_info.retailer_name}
      </span>
    ),
  },
  {
    Header: 'Search Group',
    accessor: (d) => (
      <span>
        {d.payload_to_send.group}
      </span>
    ),
  },
  {
    Header: 'Search Term',
    accessor: (d) => (
      <span>
        {d.payload_to_send.term}
      </span>
    ),
  },
  {
    Header: 'Warnings',
    accessor: (d) => warningErrorMessageDisplay(d.warnings),
  },
];

const changedTermColumns: Column<ResultsTermChangedMessage>[] = [
  {
    Header: 'Retailer',
    accessor: (d) => (
      <span>
        {d.current.retailer_name}
      </span>
    ),
  },
  {
    Header: 'Search Group',
    accessor: changedTermGroupDisplay,
  },
  {
    Header: 'Search Term',
    accessor: (d) => (
      <span>
        {d.current.term}
      </span>
    ),
  },
  {
    Header: 'Warnings',
    accessor: (d) => warningErrorMessageDisplay(d.warnings),
  },
];

const unchangedTermColumns: Column<ResultsTermUnchangedMessage>[] = [
  {
    Header: 'Retailer',
    accessor: (d) => d.current.retailer_name,
  },
  {
    Header: 'Search Group',
    accessor: (d) => d.current.group,
  },
  {
    Header: 'Search Term',
    accessor: (d) => d.current.term,
  },
];

type UploadTermsModalSummaryProps = {
  data: SearchTermsFileResults,
  setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>,
  displayModal: boolean,
};

function UploadTermsModalSummary(props: UploadTermsModalSummaryProps): JSX.Element {
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<AsyncState>('uninitialized');
  const [disableUpload, setDisableUpload] = useState<boolean>(false);
  const currentOrgId = useSelector(selectCurrentOrgId);
  const { data, displayModal, setDisplayModal } = props;
  const [applyButtonVariant, setApplyButtonVariant] = useState<'primary' | 'danger'>('primary');
  const [closeButtonVariant, setCloseButtonVariant] = useState<'primary' | 'danger'>('danger');
  const [applyButtonVisibility, setApplyButtonVisibility] = useState<boolean>(true);

  const {
    new_searches: newTerms,
    changed_searches: changedTerms,
    unchanged_searches: unchangedTerms,
    incompatible_searches: incompatibleTerms,
  } = data;

  const areChangesProcessed = (
    (newTerms ?? []).length + (changedTerms ?? []).length
  ) !== 0;

  const handleClose = () => {
    setDisplayModal(false);
    setUploadError(false);
    setUploadStatus('uninitialized');
  };

  const handleSubmit = () => {
    setUploadStatus('loading');
    const promiseList = [];

    if (newTerms.length > 0) {
      const newTermsPayload: SearchTermNewData[] = newTerms.map((t) => t.payload_to_send);
      promiseList.push(postSearchTermSet(currentOrgId as number, newTermsPayload));
    }

    if (changedTerms.length > 0) {
      const changedTermsPayload: SearchUpdateWithId[] = changedTerms.map(
        (t) => ({
          group: t.current.group,
          track: t.current.track,
          status: t.current.status,
          ...(t.payload_to_send),
          id: t.current.id ? t.current.id : 'missing id',
        }),
      );
      promiseList.push(putSearchTermSet(currentOrgId as number, changedTermsPayload));
    }

    Promise.all(promiseList).catch((error) => {
      sendRumError(error);
      setUploadError(true);
    })
      .finally(() => setUploadStatus('completed'));
  };

  useEffect(() => {
    if (uploadStatus === 'completed') {
      setCloseButtonVariant('danger');
      setApplyButtonVisibility(true);
      setApplyButtonVariant('primary');
    } else if (uploadStatus === 'failed' || !areChangesProcessed || uploadError) {
      setCloseButtonVariant('danger');
      setApplyButtonVisibility(false);
      setApplyButtonVariant('danger');
    } else {
      setCloseButtonVariant('danger');
      setApplyButtonVisibility(true);
      setApplyButtonVariant('primary');
    }
  }, [uploadStatus, areChangesProcessed, uploadError]);

  useEffect(() => {
    setDisableUpload(uploadStatus !== 'uninitialized' || !areChangesProcessed || uploadError);
  }, [uploadStatus, uploadError, areChangesProcessed]);

  return (
    <Modal
      className="upload-term-modal-container summary-modal"
      data-testid="upload-term-modal-container"
      show={displayModal}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Search Changes Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body
        data-testid="term-changes-summary-body"
      >
        <p>
          Please review the change summaries below and confirm your changes by pressing the&nbsp;
          <b>Apply Changes</b>
          &nbsp;button below.
        </p>
        <p>
          Search term additions or edits resulting in errors require that you address the error and
          reupload a search term&apos;s information in order for it to appear in the application.
        </p>
        <p>
          Search terms submitted with warnings will appear in the application,
          but some information associated with the search term may need to be resubmitted.
        </p>
        <p>
          All successful additions and edits may take up to 24 hours to appear in the application.
        </p>
        <ListResultItems<ResultsTermNewMessage>
          eventKey="1"
          data={newTerms}
          dataColumns={newTermColumns}
          headerClass="text-success"
          headerText="Will be Added"
        />
        <ListResultItems<ResultsTermChangedMessage>
          eventKey="2"
          data={changedTerms}
          dataColumns={changedTermColumns}
          headerClass="text-deep-sea-blue"
          headerText="Will be Changed"
        />
        <ListResultItems<ResultsTermIncompatibleMessage>
          eventKey="3"
          data={incompatibleTerms}
          dataColumns={errorColumns}
          headerClass="text-danger"
          headerText="with Errors"
        />
        <ListResultItems<ResultsTermUnchangedMessage>
          eventKey="4"
          data={unchangedTerms}
          dataColumns={unchangedTermColumns}
          headerClass="text-dark"
          headerText="Unchanged"
        />
      </Modal.Body>
      <Modal.Footer>
        {
          uploadStatus === 'completed' && !uploadError && (
            <Alert variant="success" className="w-100">
              All changes have been commited.
            </Alert>
          )
        }
        {
          uploadStatus === 'completed' && uploadError && (
            <Alert variant="danger" className="w-100">
              Failed to upload changes. Please refresh and try again.
            </Alert>
          )
        }
        {
          !areChangesProcessed && (
            <Alert variant="danger" className="w-100">
              Changes cannot be submitted because all submissions
              would result in errors or no changes.
            </Alert>
          )
        }
        <Button
          variant={closeButtonVariant}
          data-testid="upload-terms-modal-summary-close"
          onClick={handleClose}
        >
          Close
        </Button>
        {
          applyButtonVisibility && (
            <Button
              variant={applyButtonVariant}
              id="terms-upload-modal-confirm-button"
              data-testid="terms-upload-modal-confirm-button"
              onClick={handleSubmit}
              className="m-1"
              disabled={disableUpload}
              type="submit"
            >
              {
                uploadStatus === 'uninitialized' && areChangesProcessed && <>Apply Changes</>
              }
              {
                uploadStatus === 'loading' && <Spinner animation="grow" size="sm" />
              }
              {
                uploadStatus === 'completed' && <>Apply Changes</>
              }
            </Button>
          )
        }
      </Modal.Footer>
    </Modal>
  );
}

export default UploadTermsModalSummary;
