import axios from 'axios';

export type DynamicConfig = {
  COGNITO_CLIENT_ID: string,
  COGNITO_POOL_ID: string,
  ENV: 'development' | 'production' | 'local',
  MAINTENANCE_SVC_URL: string,
  PRODUCT_SVC_URL: string,
  SOV_SVC_URL: string,
  SUBSCRIPTION_SVC_URL: string,
  USER_SVC_URL: string,
  AMAZON_SELLER_CENTRAL_AUTH_URL: string,
  AMAZON_VENDOR_CENTRAL_AUTH_URL: string,
  AMAZON_APPLICATION_ID: string,
  REACT_APP_VERSION: string,
  DD_APP_ID: string,
  REACT_APP_RUM_CLIENT_TOKEN: string,
};

export class Config {
  config!: DynamicConfig;

  loadConfig = (config: DynamicConfig) => {
    this.config = config;
  };
}

const GlobalConfig = new Config();

export const waitForConfig = async () => {
  let retry = 0;
  while (GlobalConfig.config === undefined) {
    if (retry < 5) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => { setTimeout(resolve, 100); });
      retry += 1;
    } else {
      throw Error(`failed to resolve config. Retried ${retry} times`);
    }
  }
};

export const fetchConfig = async () => {
  const { data }: { data: DynamicConfig } = await axios.get('/config.json');
  GlobalConfig.loadConfig(data);
  return waitForConfig();
};

export default GlobalConfig;
