/* istanbul ignore file */

import React, { SVGProps } from 'react';

function DoorLock({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M13.333 2.667c-7.36 0-13.333 5.973-13.333 13.333s5.973 13.333 13.333 13.333c7.36 0 13.333-5.973 13.333-13.333s-5.973-13.333-13.333-13.333zM13.333 28.267c-6.773 0-12.267-5.493-12.267-12.267s5.493-12.267 12.267-12.267c6.773 0 12.267 5.493 12.267 12.267s-5.493 12.267-12.267 12.267z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M17.6 12.8c0-2.347-1.92-4.267-4.267-4.267s-4.267 1.92-4.267 4.267c0 1.6 0.907 3.040 2.24 3.733l-2.24 6.4h8.533l-2.187-6.4c1.28-0.747 2.187-2.133 2.187-3.733zM16.107 21.867h-5.547l1.707-4.96 0.32-0.853-0.8-0.427c-1.013-0.587-1.653-1.653-1.653-2.827 0-1.76 1.44-3.2 3.2-3.2s3.2 1.44 3.2 3.2c0 1.173-0.64 2.24-1.653 2.773l-0.8 0.427 0.267 0.853 1.76 5.013z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default DoorLock;
