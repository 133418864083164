/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Graph({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M29.328 16c0-7.181-5.682-13.034-12.796-13.315v-0.014h-1.066v0.014c-7.113 0.28-12.795 6.133-12.795 13.315 0 7.362 5.967 13.328 13.328 13.328 2.586 0 4.997-0.739 7.042-2.013l0.007 0.010 0.397-0.27c0.005-0.003 0.009-0.006 0.014-0.009l0.472-0.32-0.007-0.010c3.279-2.428 5.405-6.323 5.405-10.717zM27.218 11.057l-10.685 4.163v-11.468c4.774 0.206 8.848 3.153 10.685 7.305zM16 28.262c-6.761 0-12.262-5.5-12.262-12.262 0-6.582 5.214-11.968 11.729-12.248v12.412l6.973 10.265c-1.873 1.16-4.079 1.833-6.44 1.833zM23.321 25.831l-6.505-9.577 10.788-4.204c0.424 1.24 0.657 2.568 0.657 3.95 0 4.019-1.944 7.593-4.941 9.831z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Graph;
