/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Scissors({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M13.535 18.155l15.652 0.119-0.899-0.904c-0.067-0.067-6.674-6.531-15.515-0.334l-2.805-4.12c0.356-0.296 0.663-0.662 0.894-1.096 0.955-1.79 0.278-4.013-1.511-4.968-1.79-0.955-4.014-0.278-4.967 1.511-0.955 1.789-0.279 4.013 1.511 4.968 1.023 0.546 2.187 0.556 3.177 0.132l2.47 3.628-4.225-0.032c-0.139-1.15-0.816-2.216-1.916-2.803-1.79-0.954-4.013-0.278-4.967 1.512-0.955 1.789-0.279 4.013 1.511 4.967 1.789 0.954 4.014 0.278 4.968-1.511 0.193-0.36 0.315-0.737 0.379-1.117l4.904 0.037c-0.119 2.622 0.354 4.712 1.13 6.386h-1.786v1.049h3.147v-0.524h0.066c-0.023-0.038-0.043-0.080-0.066-0.119v-0.406h-0.223c-0.675-1.314-1.147-2.937-1.228-4.952l3.372 4.952h-0.872v1.049h3.147v-1.049h-1.007l-4.341-6.376zM25.412 16.6c0.381 0.204 0.715 0.41 0.999 0.605l-11.825-0.091c4.737-2.758 8.586-1.708 10.826-0.514zM6.388 12.406c-1.275-0.681-1.76-2.273-1.080-3.548s2.272-1.761 3.549-1.080c1.275 0.68 1.76 2.272 1.079 3.548-0.68 1.276-2.272 1.76-3.548 1.079zM5.987 18.731c-0.68 1.275-2.272 1.759-3.549 1.079s-1.76-2.272-1.080-3.548c0.681-1.276 2.272-1.761 3.549-1.080 1.275 0.68 1.76 2.272 1.080 3.549z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M7.343 24.532h3.147v1.049h-3.147v-1.049z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M19.931 24.532h3.147v1.049h-3.147v-1.049z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M3.147 24.532h3.147v1.049h-3.147v-1.049z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M24.127 24.532h3.147v1.049h-3.147v-1.049z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Scissors;
