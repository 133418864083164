/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Arc({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M22.453 7.573l2.987-2.987v2.453h1.067v-4.267h-4.267v1.067h2.453l-2.987 2.987c-2.72-2.56-6.293-4.107-10.293-4.107-4.373 0-8.533 1.92-11.413 5.173l0.8 0.693c0.107-0.107 0.213-0.213 0.32-0.32l9.547 9.6-3.573 3.573h-2.667l-3.733 3.733h3.413v3.413l3.733-3.733v-2.667l3.573-3.573 9.547 9.547c-0.107 0.107-0.213 0.213-0.32 0.32l0.693 0.8c3.307-2.88 5.173-7.040 5.173-11.413 0.053-4-1.493-7.627-4.053-10.293zM6.773 24.373l-1.6 1.6v-1.92h-1.867l1.6-1.6h1.92v1.92zM1.867 7.52c2.56-2.4 5.973-3.733 9.547-3.733 3.68 0 7.040 1.44 9.547 3.733l-9.547 9.547-9.547-9.547zM21.707 8.32c2.347 2.507 3.733 5.867 3.733 9.547 0 3.573-1.333 6.933-3.733 9.547l-9.547-9.547 9.547-9.547z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Arc;
