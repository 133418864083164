/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Helm({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M30.933 16.533v-1.067h-3.733c-0.107-2.88-1.227-5.44-3.040-7.467l2.667-2.667-0.747-0.747-2.667 2.667c-1.973-1.813-4.533-2.933-7.36-3.040v-3.68h-1.067v3.68c-2.827 0.107-5.387 1.28-7.36 3.040l-2.667-2.667-0.747 0.747 2.667 2.667c-1.867 2.027-3.040 4.587-3.147 7.467h-3.733v1.067h3.733c0.16 2.827 1.28 5.387 3.040 7.307l-2.667 2.667 0.747 0.747 2.667-2.667c1.973 1.813 4.533 2.933 7.36 3.040v3.84h1.067v-3.787c2.827-0.107 5.44-1.28 7.36-3.040l2.667 2.667 0.747-0.747-2.667-2.667c1.76-1.973 2.933-4.48 3.040-7.307h3.84zM26.133 15.467h-6.4c-0.107-0.8-0.427-1.547-0.907-2.133l4.533-4.533c1.6 1.76 2.613 4.107 2.773 6.667zM15.467 19.2c-1.76 0-3.2-1.44-3.2-3.2s1.44-3.2 3.2-3.2c1.76 0 3.2 1.44 3.2 3.2s-1.44 3.2-3.2 3.2zM22.613 8.053l-4.587 4.587c-0.587-0.427-1.28-0.747-2.027-0.853v-6.507c2.56 0.16 4.853 1.173 6.613 2.773zM14.933 5.28v6.453c-0.747 0.107-1.44 0.373-2.027 0.853l-4.587-4.587c1.76-1.547 4.053-2.56 6.613-2.72zM7.573 8.8l4.533 4.533c-0.48 0.587-0.8 1.333-0.907 2.133h-6.4c0.16-2.56 1.173-4.907 2.773-6.667zM4.853 16.533h6.4c0.107 0.747 0.373 1.44 0.853 2.027l-4.48 4.48c-1.653-1.707-2.667-4-2.773-6.507zM8.32 23.84l4.48-4.48c0.587 0.48 1.333 0.8 2.133 0.907v6.347c-2.56-0.16-4.853-1.173-6.613-2.773zM16 26.56v-6.347c0.8-0.107 1.547-0.427 2.133-0.907l4.48 4.48c-1.76 1.653-4.053 2.667-6.613 2.773zM23.36 23.093l-4.48-4.48c0.427-0.587 0.747-1.28 0.853-2.027h6.4c-0.16 2.453-1.173 4.747-2.773 6.507z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Helm;
