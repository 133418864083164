import { spAPILocalStoreKeyPrefix } from './constants';

export const convertToString = (v: string | string[]) => (
  Array.isArray(v) ? JSON.stringify(v) : v
);

export const displayStringAsLabel = (s: string) => s
  .replace(/_/g, ' ')
  .replace(
    /\w\S*/g,
    (txt) => (
      txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
    ),
  )
  .replace(/ Pos/g, ' POS');

// takes a textlength and maxlength argument and generates color class to match
// text >= 80 - color is red
// text < 80  - color unchanged
// text > 80  - color is yellow

export const getTextColor = (textLength: number, maxLength: number) => {
  if (textLength >= maxLength) {
    return 'text-coral-red';
  } if (textLength > maxLength * 0.8) {
    return 'text-warning';
  }
  return '';
};

export const parseValue = (s: string | string[]) => {
  if (Array.isArray(s)) {
    return s;
  }
  try {
    return JSON.parse(s);
  } catch {
    return s;
  }
};

export const generateSpLocalKey = (id:string) => `${spAPILocalStoreKeyPrefix}-${id}`;

export default {
  convertToString,
  displayStringAsLabel,
  getTextColor,
  parseValue,
  generateSpLocalKey,
};
