import axios, { AxiosInstance } from 'axios';
import { headers } from '..';
import useAuthenticationInterceptors from '../auth/useAuthenticationInterceptors';
import {
  SearchTermFull,
  SearchTermNewData,
  SearchTermPortfolioPayload,
  SearchTermsFileResults,
  SearchUpdateWithId,
} from './types';

let sovService: AxiosInstance;

export const initializeSovController = (serviceUrl: string) => {
  sovService = axios.create({
    baseURL: serviceUrl,
    headers: {
      scheme: 'https',
    },
  });
  useAuthenticationInterceptors(sovService);
};

export const getSearches = (
  orgId: number,
) => sovService.get<SearchTermFull[]>(
  '/v2/searches/',
  {
    headers: {
      ...headers(orgId),
      'Content-Type': 'multipart/form-data',
    },
  },
);

export const getSearchTermsFile = (
  orgId: number,
  filterPayload: SearchTermPortfolioPayload,
) => sovService.post<Blob>(
  `/v2/search_terms_file/${orgId}/filtered/`,
  filterPayload,
  {
    headers: {
      ...headers(orgId),
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  },
);

export const postSearchTermsFile = (
  orgId: number,
  form: FormData,
) => sovService.post<SearchTermsFileResults>(
  `/v2/search_terms_file/${orgId.toString()}`,
  form,
  {
    headers: {
      ...headers(orgId),
      'Content-Type': 'multipart/form-data',
    },
  },
);

export const postSearchTermSet = (
  orgId: number,
  data: SearchTermNewData[],
) => sovService.post(
  `/v2/search-set/${orgId.toString()}`,
  data,
  {
    headers: headers(orgId),
  },
);

export const putSearchTermSet = (
  orgId: number,
  data: SearchUpdateWithId[],
) => sovService.put(
  `/v2/search-set/${orgId.toString()}`,
  data,
  {
    headers: headers(orgId),
  },
);
