/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Compass({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M17.992 5.493c0.414-0.47 0.674-1.080 0.674-1.755 0-1.472-1.193-2.665-2.666-2.665s-2.666 1.193-2.666 2.665c0 0.675 0.259 1.285 0.674 1.755-6.12 0.957-10.803 6.251-10.803 12.64 0 7.067 5.729 12.795 12.795 12.795s12.795-5.728 12.795-12.795c0-6.389-4.683-11.683-10.803-12.64zM16 2.139c0.882 0 1.599 0.717 1.599 1.599s-0.717 1.599-1.599 1.599-1.599-0.717-1.599-1.599 0.717-1.599 1.599-1.599zM16 29.861c-6.467 0-11.729-5.261-11.729-11.729s5.261-11.729 11.729-11.729 11.729 5.261 11.729 11.729-5.261 11.729-11.729 11.729z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M9.605 24.538l8.675-4.109 4.109-8.676-8.704 4.081-4.081 8.703zM14.247 17.15l2.721 2.721-5.138 2.433 2.417-5.155zM17.722 19.118l-2.721-2.721 5.155-2.417-2.434 5.139z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Compass;
